import utils from "./utils";
import Util from "./utils";
export default function goPrint(orders, cb) {
    let orderCodes = orders.map((ord) => ord.code);
    var mywindow = window.open("", "mywindow", "status=1,width=1000,height=1000");
    mywindow.document.write("<html><head><title>Invoice</title>");
    mywindow.document.write("</head>" +
        '<body style="font-family: Lucida Console,Lucida Sans Typewriter,monaco,Bitstream Vera Sans Mono,monospace; -webkit-print-color-adjust: exact; " >');
    orders.forEach((data, indexP) => {
        mywindow.document.write('<content style="width:100%; font-size:10pt; color: #000;">' +
            `<div
            style="text-align:center; margin:5pt 0; font-size:16pt; font-weight:bold; color:000;"
          > <b>${data.orderNo}</b>
          </div>` +
            `<div
          style="display:flex; justify-content: space-between; margin-top:5pt;"
        >
          <span
            >Customer: ${data["customer"]["name"] ? data["customer"]["name"] : "-"}</span
          >
		  <span
            >Table: ${data["customer"]["table"] ? data["customer"]["table"] : "-"}</span
          >
        </div>` +
            '<div style="display:flex; justify-content: space-between; margin-top:3pt;">' +
            "<span>ID. " +
            data.code.toUpperCase().substr(0, 8) +
            "</span>" +
            "<span>" +
            Util.formatDate(new Date(data.createdAtOnLocal), "detail") +
            "</span>" +
            "</div>" +
            '<hr style="margin-top:5pt;"/>');
        data.items
            .filter((it) => !utils.checkIfKeyExist(it.category, "printable") ||
            it.category["printable"].includes("kitchen"))
            .forEach((item, i) => {
            var margin = "3mm";
            if (i > 0) {
                margin = "5mm";
            }
            mywindow.document.write('<div style="display:flex; flex-direction:row; align-items:center; margin-top: ' +
                margin +
                '; ">' +
                '<div style="flex:1; display:flex; flex-direction:column;">' +
                '<div style="font-weight:bold;">' +
                item.name +
                "</div>" +
                "</div>" +
                '<div style="text-align:right;">' +
                item.amount +
                "</div>" +
                "</div>");
            if ((item === null || item === void 0 ? void 0 : item.isAvailableProduct) === false) {
                mywindow.document.write(`<div style="display:flex; flex-direction:row; margin-top: 2mm; font-size:9pt;">(Produk tidak aktif/tidak tersedia)</div>`);
            }
            item.additionals.forEach((adds) => {
                adds.items.forEach((add) => {
                    var parent = "";
                    add["nameParent"] ? (parent = add["nameParent"]) : "";
                    mywindow.document.write('<div style="display:flex; flex-direction:row; align-items:center; margin-top:5pt;">' +
                        '<div style="flex:1; margin-left:10pt; text-align:left">' +
                        parent +
                        " - " +
                        add.name +
                        "</div>" +
                        '<div style="text-align:right;">' +
                        item.amount +
                        "</div>" +
                        "</div>" +
                        "");
                    if ((add === null || add === void 0 ? void 0 : add.isAvailableModifier) === false) {
                        mywindow.document.write(`<div style="display:flex; flex-direction:row; margin-top: 2mm; font-size:9pt; margin-left:10pt;">(Modifier tidak aktif/tidak tersedia)</div>`);
                    }
                });
            });
            item.toppings.forEach((topping) => {
                mywindow.document.write('<div style="display:flex; flex-direction:row; align-items:center; margin-top:5pt;">' +
                    '<div style="flex:1; margin-left:10pt; text-align:left">' +
                    topping.name +
                    "</div>" +
                    '<div style="text-align:right;">' +
                    item.amount +
                    "</div>" +
                    "</div>");
            });
            if (item.notes) {
                mywindow.document.write('<div style="margin-top:5px;">' +
                    `Notes: ${item.notes}` +
                    "</div>" +
                    "");
            }
        });
        data.promos.forEach((prom) => {
            prom["bonus"]
                .filter((it) => !utils.checkIfKeyExist(it.category, "printable") ||
                it.category["printable"].includes("kitchen"))
                .forEach((bon) => {
                mywindow.document.write('<div style="display:flex; flex-direction:row; align-items:center; margin-top:6pt;">' +
                    '<div style="flex:1; text-align:left"> Free ' +
                    bon.name +
                    "</div>" +
                    '<div style="text-align:right;">' +
                    bon.qty +
                    "</div>" +
                    "</div>" +
                    "");
            });
        });
        mywindow.document.write('<hr style="margin-top:5pt; margin-bottom:5pt;" />');
        if (orders.length > 1 && orders[indexP] + 1 != orders.length) {
            mywindow.document.write('<div style="margin-top:25pt; margin-bottom:25pt;"></div>');
        }
        mywindow.document.write("</div>");
        mywindow.document.write("</content>" + "");
    });
    mywindow.document.write("</body></html>");
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    setTimeout(() => mywindow.close(), 1000);
    return cb(orderCodes);
}
